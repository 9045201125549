import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src353023853/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src353023853/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/codebuild/output/src353023853/src/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import msw_blOUH7XH34 from "/codebuild/output/src353023853/src/src/plugins/msw.ts";
import sentry_client_KAXFuL2wum from "/codebuild/output/src353023853/src/src/plugins/sentry.client.ts";
import vue_query_client_kZwE0gviwT from "/codebuild/output/src353023853/src/src/plugins/vue-query.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  msw_blOUH7XH34,
  sentry_client_KAXFuL2wum,
  vue_query_client_kZwE0gviwT
]