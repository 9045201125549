import { default as indexrotS6uZhO0Meta } from "/codebuild/output/src353023853/src/src/pages/[companySlug]/faq/[id]/index.vue?macro=true";
import { default as indexAqVh3XKxhVMeta } from "/codebuild/output/src353023853/src/src/pages/[companySlug]/important-news/index.vue?macro=true";
import { default as indexyvFqNkAJyHMeta } from "/codebuild/output/src353023853/src/src/pages/[companySlug]/index.vue?macro=true";
import { default as indexjkQ1q83OFLMeta } from "/codebuild/output/src353023853/src/src/pages/[companySlug]/news/[id]/index.vue?macro=true";
import { default as index9p9VxQrSR9Meta } from "/codebuild/output/src353023853/src/src/pages/[companySlug]/news/index.vue?macro=true";
import { default as indexmIUmVp5hr5Meta } from "/codebuild/output/src353023853/src/src/pages/[companySlug]/preview/faq/index.vue?macro=true";
import { default as indexyZvnaM54QXMeta } from "/codebuild/output/src353023853/src/src/pages/[companySlug]/preview/news/index.vue?macro=true";
export default [
  {
    name: "companySlug-faq-id",
    path: "/:companySlug()/faq/:id()",
    component: () => import("/codebuild/output/src353023853/src/src/pages/[companySlug]/faq/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-important-news",
    path: "/:companySlug()/important-news",
    component: () => import("/codebuild/output/src353023853/src/src/pages/[companySlug]/important-news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug",
    path: "/:companySlug()",
    component: () => import("/codebuild/output/src353023853/src/src/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news-id",
    path: "/:companySlug()/news/:id()",
    component: () => import("/codebuild/output/src353023853/src/src/pages/[companySlug]/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news",
    path: "/:companySlug()/news",
    component: () => import("/codebuild/output/src353023853/src/src/pages/[companySlug]/news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-faq",
    path: "/:companySlug()/preview/faq",
    component: () => import("/codebuild/output/src353023853/src/src/pages/[companySlug]/preview/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-news",
    path: "/:companySlug()/preview/news",
    component: () => import("/codebuild/output/src353023853/src/src/pages/[companySlug]/preview/news/index.vue").then(m => m.default || m)
  }
]